import { gql, useMutation } from '@apollo/client';

const ACKNOWLEDGE_SYSTEM_MESSAGE= gql`
  mutation($ack: SystemMessageInput) 
  {
    AckSystemMessage(ack: $ack) {
      result {
        id
        username
      }
    }
  }
`;

export function useMessageMutations() {

  const [acknowledge] = useMutation(ACKNOWLEDGE_SYSTEM_MESSAGE);
  
  return { acknowledge };
}
