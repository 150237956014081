import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useQuery, gql} from "@apollo/client";
import Form from 'react-bootstrap/Form';
import {Row, Col, Button} from 'react-bootstrap';
import withAlerts from '../../lib/withAlerts';
import {useBulkOTACheck} from '../../lib/useOTACheck';
import FirmwareAssignmentConfirmModal from './firmwareAssignmentConfirmModal';

export const FirmwareSelect = (props) => {
  const [hideButton, setHideButton] = useState(true);
  const {firmware, deviceModelID, onChange, otaState} = props || {};
  const {id: scfid, is_active, version: rowversion} = firmware || {};
  const inputRef = useRef(scfid);
  const bulkOTARunning = useBulkOTACheck();
  const currentDeviceOTAInProgress = otaState === "in_progress" || false;
  
  const {data} = useQuery(FIRMWARES,
    {
      variables: {
        filters: {
          combineAnd: [
            {
              field: "device_model_id", value: `${deviceModelID}`
            },
            {
             field: "options.sort_fields", value: "created_at.dsc"
            }
          ]
        }
      }
    });

  //filter out "old" versions
  const queryFirmwares = data?.Firmwares?.reduce((res, qf) => {
    if (qf.description !== 'old') {
      res[qf.description] = qf;
      return res;
    }
    return res;
  }, []) ?? [];

  const firmwareOnChange = async () => {
    let newVal = inputRef.current.value;
    if(newVal === scfid) {
      setHideButton(true);
    }
    onChange(newVal);
  };

  const handleButton = () => {
    let newVal = inputRef.current.value;
    if((newVal === scfid) || (newVal === '')) {
      setHideButton(true)
    } else {
      setHideButton(false);
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    inputRef.current.value = scfid;
    setHideButton(true);
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>Update Firmware</Form.Label>
        <Form.Select
          ref={inputRef}
          defaultValue={''}
          onChange={handleButton}
          disabled={bulkOTARunning || currentDeviceOTAInProgress}
          >
          <option key={''} value={''}> </option>
          {
            queryFirmwares?.latest && <option key={queryFirmwares?.latest.id} value={queryFirmwares?.latest.id}>{`${queryFirmwares?.latest.version} (${queryFirmwares?.latest.description})`}</option>
          }
          {
            queryFirmwares?.rollback && <option key={queryFirmwares?.rollback.id} value={queryFirmwares?.rollback.id}>{`${queryFirmwares?.rollback.version} (${queryFirmwares?.rollback.description})`}</option>
          }
          {
            queryFirmwares?.new && <option key={queryFirmwares?.new.id} value={queryFirmwares?.new.id}>{`${queryFirmwares?.new.version} (${queryFirmwares?.new.description})`}</option>
          }

        </Form.Select>
      </Form.Group>

      <Form.Group hidden={!bulkOTARunning}>
        <Form.Label className='noCapitalize'>Firmware update to all SafeCases in progress, please wait for the update to complete.</Form.Label>
      </Form.Group>
      <Form.Group hidden={!currentDeviceOTAInProgress}>
        <Form.Label className='noCapitalize'>Firmware update in progress, please wait for the update to complete.</Form.Label>
      </Form.Group>
      
      <Form.Group hidden={hideButton}>
        <Row className="mb-3">
          <Col>
            <div className="d-grid gap-2">
              <FirmwareAssignmentConfirmModal onChange={firmwareOnChange} />
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <span className="link-span" onClick={handleCancel}>Cancel</span>
          </Col>
        </Row>
      </Form.Group>
    </>
  );
}

FirmwareSelect.propTypes = {
  firmware: PropTypes.shape({
    id: PropTypes.string,
    is_active: PropTypes.bool,
    version: PropTypes.string
  }),
  deviceModelID: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const FIRMWARES = gql`
  query getFirmwares($filters: GenericFilters!) {
    Firmwares (filters: $filters) {
      id
      version
      description
      created_at
    }
  }
`;

export default FirmwareSelect;
