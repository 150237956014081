import { React } from 'react'
import {NavLink} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb, faUsers, faLock, faGear, faKey, faMobile, faListCheck, faArrowRightFromBracket, faCircleQuestion, faSquareTerminal } from '@fortawesome/pro-light-svg-icons';
import { endKeycloakSession } from '../components/auth/signOut';
import {useReportingMutations} from '../components/auth/siemReporterMutations';

const SideNav = (props) => {
  const {log} = useReportingMutations();
  
  const handleLogout = async () => {
    //Log the event
    let event = {
      name: "portalLogout"
    };
    
    const logged = await log({variables: {event: event}});
    
    if(logged){
      return endKeycloakSession();
    }
  }

  return (
    <div className="side-nav" data-testid="side-nav">
      <div className="side-nav-section">
        <span className="side-nav-header">
          Monitoring
        </span>

        <NavLink end to="/">
          <FontAwesomeIcon icon={faLightbulb} size="lg" fixedWidth/>
          <span data-testid="dashboard-nav" className="nav-link-text">Dashboard</span>
        </NavLink>
      </div>
      
      <div className="side-nav-section">
        <span className="side-nav-header">
          Management
        </span>

        <NavLink to="/users">
          <FontAwesomeIcon icon={faUsers} size="lg" fixedWidth/>
          <span data-testid="users-nav" className="nav-link-text">Users</span>
        </NavLink>

        <NavLink to="/devices">
          <FontAwesomeIcon icon={faMobile} size="lg" fixedWidth/>
          <span data-testid="safecases-nav" className="nav-link-text">SafeCases</span>
        </NavLink>

        <NavLink to="/hdm-configuration">
          <FontAwesomeIcon icon={faGear} size="lg" fixedWidth/>
          <span className="nav-link-text">HDM Profiles</span>
        </NavLink>

        <NavLink to="/policies">
          <FontAwesomeIcon icon={faListCheck} size="lg" fixedWidth/>
          <span data-testid="policies-nav" className="nav-link-text">Policies</span>
        </NavLink>
        
        {/* <NavLink to="/groups"> */}
        {/*   <FontAwesomeIcon icon={faUsers} size="lg" fixedWidth/> */}
        {/*   <span className="nav-link-text">Groups</span> */}
        {/* </NavLink> */}

        {/* <NavLink to="/geofences"> */}
        {/*   <FontAwesomeIcon icon={faLocation} size="lg" fixedWidth/> */}
        {/*   <span className="nav-link-text">Geofences</span> */}
        {/* </NavLink> */}
      </div>

      <div className="side-nav-section">
        <span className="side-nav-header">
          Administration
        </span>

        <NavLink to="/logs">
          <FontAwesomeIcon icon={faSquareTerminal} size="lg" fixedWidth/>
          <span data-testid="logs-nav" className="nav-link-text">Logs</span>
        </NavLink>

        <NavLink to="/support">
          <FontAwesomeIcon icon={faCircleQuestion} size="lg" fixedWidth/>
          <span data-testid="support-nav" className="nav-link-text">Support</span>
        </NavLink>

{/*         <NavLink to="/subscriptions"> */}
{/*           <FontAwesomeIcon icon={faLock} size="lg" fixedWidth/> */}
{/*           <span className="nav-link-text">Subscriptions</span> */}
{/*         </NavLink> */}
{/*  */}
{/*         <NavLink to="/hdm-keys"> */}
{/*           <FontAwesomeIcon icon={faKey} size="lg" fixedWidth/> */}
{/*           <span className="nav-link-text">HDM Keys</span> */}
{/*         </NavLink> */}

        <a className="pointer logout" onClick={handleLogout}>
          <FontAwesomeIcon icon={faArrowRightFromBracket} size="lg" fixedWidth/>
          <span className="nav-link-text">Sign Out</span>
        </a>
      </div>
    </div>
  )
}

export default SideNav;
