import { React, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Row, Col, Form, Badge } from 'react-bootstrap';
import { usePortalContext } from '../../lib/context/portalContext';
import { useDeviceMutations } from './deviceMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import {NIL} from 'uuid';
import FirmwareSelect from './firmwareSelect';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/pro-solid-svg-icons';
import dateFormat from '../../lib/dateFormat';

const SafeCaseDetails = props => {
  const { togglePortal, isShowing, rowData, origin } = usePortalContext();
  const { enable, disable, setCriteria, startOTA, stopOTA } = useDeviceMutations();
  const [showDeviceAssign, setShowDeviceAssign] = useState(false);
  const {alerts} = props;

  let deviceStatus = (rowData?.is_active) ? "Active" : "Disabled";
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  const renderingDeviceFields = rowData?.serial_number;
  const config = rowData?.device_configuration ?? {};

  const handleUnassignSC = async() => {
    const newAssignment = await setCriteria({ variables: 
      {
        serial_number: rowData?.serial_number,
        field: 'user_id',
        value: NIL
      }
    });

    if(newAssignment?.errors) {
      let userFacingError = ('Unassignment failed');
      console.error(userFacingError + JSON.stringify(newAssignment?.errors));
      alerts.error({title: 'Edit Assignment Error', msg: userFacingError});
    } else {
      alerts.success({title: 'Edit Assignment', msg: `Device unassigned.`});
    }
    togglePortal();
  }

  const handleAssignSafecase = async (formData) => {
    const newAssignment = await setCriteria({ variables: 
      {
        serial_number: formData?.newserial,
        field: 'user_id',
        value: rowData?.user?.id
      }
    });

    if(newAssignment?.errors) {
      let userFacingError = ('Assignment failed');
      console.error(userFacingError + JSON.stringify(newAssignment?.errors));
      alerts.error({title: 'Edit Assignment Error', msg: userFacingError});
    } else {
      alerts.success({title: 'Edit Assignment', msg: `Device assignment updated.`});
    }
    togglePortal();

    // have safecase associate this user to it? user ID to device set criteria?
    setShowDeviceAssign(false);
  }
  
  const handleFirmwareChange = async (desiredFID) => {
    if(desiredFID && desiredFID !== rowData?.firmware?.id) {
      const startDeviceOTA = await startOTA({variables: 
        {
          serial_number: rowData?.serial_number,
          firmware_id: desiredFID
        }
      });

      if(startDeviceOTA.errors) {
        alerts.error({title: 'Firmware Update', msg: 'Error updating firmware.'});
      } else {
        alerts.success({title: 'Firmware Update', msg:'Firmware update started.'});
      }  
    }
    togglePortal();
    return null;    
  }

  return ( <>
        <div className="offcanvas-form" data-testid="offcanvas-safecase">
          <Form className="mb-3" >
          { rowData?.serial_number && 
            <Row>
              <Form.Group className="mb-3">
                <Form.Label >Serial Number</Form.Label>
                <Form.Control data-testid="sc-offcanvas-serial-number" placeholder="684A341" defaultValue={rowData?.serial_number} readOnly={true} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label >SafeCase Model</Form.Label>
                <Form.Control data-testid="sc-offcanvas-sc-model" placeholder="M0001" defaultValue={rowData?.product_type?.description} readOnly={true} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label >Current SafeCase Firmware</Form.Label>
                <Form.Control placeholder="1.0.0.0" defaultValue={rowData?.reported_firmware?.version} readOnly={true} />
              </Form.Group>
              <FirmwareSelect
                firmware={rowData?.reported_firmware}
                deviceModelID={rowData?.device_model_id}
                deviceId={rowData?.id}
                onChange={handleFirmwareChange}
                otaState={rowData?.ota_state}
              />
            </Row>
            }
            {/* <Row> */}
            {/*   <Col className="rules-pills"> */}
            {/*     <Form.Group className="mb-3"> */}
            {/*       <Form.Label >Fulcrum</Form.Label> */}
            {/*       <div className="d-flex"> */}
            {/*         <Badge pill bg="none"> */}
            {/*           <FontAwesomeIcon size="lg" icon={config.is_anti_surveillance_enabled ? faCheck : faXmark} color={config.is_anti_surveillance_enabled ? 'green' : 'red'} /> {config.is_anti_surveillance_enabled ? 'Active' : 'Inactive'} */}
            {/*         </Badge> */}
            {/*       </div> */}
            {/*     </Form.Group> */}
            {/*   </Col> */}
            {/*   <Col> */}
            {/*     <Form.Group className="mb-3"> */}
            {/*       <Form.Label >Expiration Date</Form.Label> */}
            {/*       <div className="d-block">{config.is_anti_surveillance_enabled ? dateFormat(config.is_anti_surveillance_enabled_expiry) : ''}</div> */}
            {/*     </Form.Group> */}
            {/*   </Col> */}
            {/* </Row> */}
            {/*  */}
            {/* <Row> */}
            {/*   <Col className="rules-pills"> */}
            {/*     <Form.Group className="mb-3"> */}
            {/*       <Form.Label >Privoro HDM</Form.Label> */}
            {/*       <Badge pill bg="none"> */}
            {/*           <FontAwesomeIcon size="lg" icon={config.is_hdm_enabled ? faCheck : faXmark} color={config.is_hdm_enabled ? 'green' : 'red'} /> {config.is_hdm_enabled ? 'Active' : 'Inactive'} */}
            {/*         </Badge> */}
            {/*     </Form.Group> */}
            {/*   </Col> */}
            {/*   <Col> */}
            {/*     <Form.Group className="mb-3"> */}
            {/*       <Form.Label ></Form.Label> */}
            {/*       <div className="d-block">{config.is_hdm_enabled ? dateFormat(config.is_hdm_enabled_expiry) : ''}</div> */}
            {/*     </Form.Group> */}
            {/*   </Col> */}
            {/* </Row> */}
            
            <Row>
              <Col>
                <div className="d-grid gap-2">
                { (origin?.pathname !== "/devices" && rowData?.serial_number) && <>
                <Button variant="secondary" onClick={handleUnassignSC}>Unassign Safecase</Button>
                </> }
                { (origin?.pathname !== "/devices") && <>
                <Button variant= { showDeviceAssign ? "secondary" : "primary"} 
                onClick={() => {setShowDeviceAssign(true);}}>Assign a SafeCase</Button>
                </> }
                
                </div>
              </Col>
            </Row>
            
          </Form>
          
        { /*secondary form for reassigning SC */ showDeviceAssign &&
          <Form className="mb-3" onSubmit={handleSubmit(handleAssignSafecase)}>
              <Row>
              <Form.Group className="mb-3">
                <Form.Label >Serial Number of Case to Assign</Form.Label>
                <Form.Control {...register("newserial")} type="text" placeholder="684A341" />
              </Form.Group>
              </Row>
              <Row>
              <Col>
                <div className="d-grid gap-2">
                { origin?.pathname !== "/devices" && 
                <Button type="submit">Confirm New Assignment</Button>  }
                </div>
              </Col>
            </Row>
          </Form>
        }
        </div>
    </> )
}

export default compose(
  withAlerts
)(SafeCaseDetails);
