import { gql, useMutation } from '@apollo/client';

const LOG = gql`
  mutation ($event: EventInput) {
    LogReport(event: $event)
  }
`;

export function useReportingMutations() {

  const [log] = useMutation(LOG);

  return { log };
}
