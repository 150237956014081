import React, {useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';
import {useQuery,gql} from "@apollo/client";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencil, faTimes} from '@fortawesome/pro-light-svg-icons';
import {faSpinnerThird} from '@fortawesome/pro-duotone-svg-icons';
import Form from 'react-bootstrap/Form';
import {useDeviceMutations} from './deviceMutations';
import withAlerts from '../../lib/withAlerts';
import {compose} from 'redux';
import cx from 'classnames';
import {NIL} from 'uuid';

export const FirmwareAssignmentModal = (props) => {
  const {
    useLink,
    row: {
      serial_number,
      firmware_id,
      firmware,
      reported_firmware_id,
      reported_firmware,
      ota_state,
      ota_progress,
      is_active,
      is_delete,
      device_model_id
    }
  } = props ?? {};
  const {id} = firmware ?? {};
  const model_id = device_model_id ?? '';
  const {data} = useQuery(FIRMWARES,
    {
      variables: {
        filters: {
          combineAnd: [
            {
              field: "device_model_id", value: `${model_id}`
            },
            {
              field: "is_active", value: "true"
            }
          ]
        }
      }
    });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => is_active && setShow(true);
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const handleHideCancelConfirm = (e) => {
    e?.stopPropagation();
    setShowCancelConfirm(false);
  }
  const handleShowCancelConfirm = (e) =>  {
    e?.stopPropagation();
    setShowCancelConfirm(true);
  }
  const {success, error} = props.alerts;
  const inputRef = useRef(id);
  const {startOTA, stopOTA} = useDeviceMutations();

  const queryFirmwares = data?.Firmwares ?? [];

  const onSubmit = async () => {
    
    let newVal = inputRef.current.value;
    if(newVal === id) return;

    const startDeviceOTA = await startOTA({variables: 
      {
        serial_number,
        firmware_id: newVal
      }
    });
    if(startDeviceOTA.errors) {
      error({title: 'Firmware Update', msg: 'Error updating firmware.'});
    } else {
      success({title: 'Firmware Update', msg: 'Firmware update started.'});
      handleClose();      
    }
  }

  const onCancel = async (e) => {
    e?.stopPropagation();
    handleHideCancelConfirm();
    const stopDeviceOTA = await stopOTA({variables:{serial_number}});
    
    if(stopDeviceOTA.errors) {
      error({title: 'Cancel Firmware Update', msg: 'Error canceling firmware update.'});
    } else {
      success({title: 'Cancel Firmware Update', msg: 'Firmware update canceled.'});
      handleClose();      
    }
  }

  const showCancel = (ota_state === "in_progress" || ( (reported_firmware_id !== firmware_id) && (firmware_id !== NIL && reported_firmware_id !== NIL ) ));
  const showSpinner = ota_state === "in_progress";
  
  return (
    <>
      { ota_state === 'in_progress' ?
        <>
          <b className="privoro-white me-2" style={{fontWeight:'normal', fontSize: 'x-small'}}>
            {ota_progress}%
          </b> 
          { showCancel && <FontAwesomeIcon onClick={handleShowCancelConfirm} color="red" icon={faTimes} size="lg" className="me-2 pointer"/>}
          { showSpinner && <FontAwesomeIcon icon={faSpinnerThird} size="lg" className="me-2 fa-spin"/>}
          <span>{firmware?.version ?? ''}</span>
        </>
        :
        <>
          { showCancel && <FontAwesomeIcon onClick={handleShowCancelConfirm} color="red" icon={faTimes} size="lg" className="me-2 pointer"/>}
          <span>{reported_firmware?.version ?? ''}</span>
        </>
      }
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showCancelConfirm}
        onHide={handleHideCancelConfirm}
        animation={false}
      >
        <Modal.Header >
          <Modal.Title>Cancel Firmware Update</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Row>
          <Col>
            <b>Cancel Firmware Update From {reported_firmware?.version ?? "unknown"} To {firmware?.version ?? "unknown"}?</b>
          </Col>
        </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <Button onClick={handleHideCancelConfirm} variant="secondary">Cancel</Button> 
            <Button onClick={onCancel} className="ms-2">Submit</Button> 
          </div>
        </Modal.Footer>
      </Modal>      
    </>
  );
}

FirmwareAssignmentModal.defaultProps = {
  useLink: false
};

FirmwareAssignmentModal.propTypes = {
  row: PropTypes.object,
  useLink: PropTypes.bool
};

const FIRMWARES = gql`
  query getFirmwares($filters: GenericFilters!) {
    Firmwares (filters: $filters) {
      id
      version
    }
  }
`;

export default compose(
  withAlerts
)(FirmwareAssignmentModal);
