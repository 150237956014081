import { useState, useEffect } from 'react';
import { Badge } from 'react-bootstrap';
import ActionBar from '../actionBar';
import { gql, useQuery } from '@apollo/client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { faCircle, faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { faWifi, faWifiSlash } from '@fortawesome/sharp-solid-svg-icons';
import Table from '../table';
import { pageSizeRecoil } from '../recoil';
import { useRecoilValue } from 'recoil';
import variables from '../../styles/_variables.module.scss';
import {dateFormat} from '../../lib/dateFormat';
import FilterBar from './filterBar';
import {useSearch, useSort, useFilter} from '../../lib/useQueryModifiers';
import Tooltip from '../tooltip';

export const Dashboard = props => {
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [filter, setFilter, orFilter] = useFilter();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil);
  const { data, loading } = useQuery(DEVICE_STATES, {
    'variables': {
      'filters': {
        'combineAnd': [
          ...search,
          ...filter,
          ...sort,
          {
            "field": "with.device_user",
            "value": "1"
          },
          {
            "field": "with.policy_alert",
            "value": "1"
          },
          {
            "field": "with.policy_alert",
            "value": "1"
          },
          {
            "field": "with.device__user__product_type__device_configuration",
            "value": "1"
          },
          {
            field: "search.fields",
            value: `device.user.firstname`,
          },
          {
            field: "search.fields",
            value: `device.user.lastname`,
          },
          {
            field: "search.fields",
            value: `device.serial_number`,
          },
          {
            field: "search.fields",
            value: `device.user.username`,
          },
          {
            field: "options.page.num",
            value: `${pageNum}`
          },
          {
            field: "options.page.len",
            value: `${pageSize}`
          }
        ],
        'combineOr': [
          ...orFilter
        ]
      }
    },
    skip: !sort.length,
    pollInterval: 10000
  });

  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    setPageNum(1)
  }, [search, filter]);

  let totalQueried = data?.DeviceStates?.metadata?.page?.records ?? totalRecordsState;

  useEffect(() => {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  let deviceStates = data?.DeviceStates?.result ?? [];

  const hdmPresetFormatter = (cell, row) => {

    let tmp = "N/A";    
    
    if(
      row?.device?.product_type?.greyhound_family &&
      row?.device_configuration?.is_hdm_enabled_expiry > Math.floor(Date.now() / 1000)
      ) {
      if (cell === "0") {
        tmp = 'All On';
      } else if (cell === "1" || cell === "2") {
        tmp = cell;
      } else if (cell === "") {
        return tmp;
      } 
    }
    return tmp;
  }

  const usernameFormatter = (cell, row) => {
    if(!cell || cell.length === 0) {
      return (
        <Tooltip id={`un-${row.id}`} content="No user assigned">
          <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
        </Tooltip>
      );
    } 
    return cell;
  } 

  const columns = [
    {
      dataField: 'device.id',
      hidden: true,
      sort: true
    },
    {
      dataField: 'device.serial_number',
      text: 'SAFECASE SERIAL NO.'
    },
    {
      dataField: 'device.user.firstname',
      text: 'First Name',
    },
    {
      dataField: 'device.user.lastname',
      text: 'Last Name',
    },
    {
      dataField: 'device.user.username',
      text: 'User ID',
      formatter: usernameFormatter
    },
    {
      dataField: 'hdm_preset_option',
      text: 'HDM Preset',
      formatter: hdmPresetFormatter
    },
    {
      dataField: 'is_check_in',
      text: 'CHECKED-IN STATUS',
      align: 'center',
      headerStyle: {textAlign: 'center'},
      formatter: (cell, row) => {
        return (
          <Badge pill data-testid="check-in-badge" style={{padding: '0.6rem 1rem', fontWeight: 'normal', color: cell ? variables.white : variables.black, background: cell ? variables.privoroBlue : variables.privoroOffGray}} bg="reset">
            {cell ? 'Checked in' : 'Checked out'}
          </Badge>
          );
      },
    },
    {
      dataField: 'is_antisurveillance_violation',
      text: 'PROTECTED MODE',
      align: 'center',
      headerStyle: {textAlign: 'center'},
      formatter: (cell, row) => {
        if(cell) {
          return (
            <Tooltip id={`as-${row.id}`} content="Protected mode disengaged">
              <div className="fa-lg me-4">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} size="2x" color={cell ? variables.privoroGold : variables.privoroBlue} />
                  <FontAwesomeIcon data-testid="as-violation-icon" transform="shrink-1 right-6" icon={cell ? faTriangleExclamation : faCheck } color={cell ? variables.black : variables.white}/>
                </span>
              </div>
            </Tooltip>
          );
        }
      }
    },
    {
      dataField: 'is_low_batt_violation',
      text: 'Battery Level',
      align: 'center',
      headerStyle: {textAlign: 'center'},
      formatter: (cell, row) => {
        const content = cell ? 'Battery level insufficient' : 'Battery level';
        if(cell) {
          return (
            <Tooltip id={`as-${row.id}`} content={content}>
              <Badge pill style={{padding: '0.6rem 1rem', fontWeight: 'normal', color: variables.black, background: variables.privoroGold}} bg="reset">
                <FontAwesomeIcon data-testid="battery-violation-icon" size="lg" transform="grow-3" className="me-2" icon={cell ? faTriangleExclamation : faCheck} color={variables.black} />
                {row.battery_life}%
              </Badge>
            </Tooltip>
          );
        } else {
          return `${row.battery_life}%`;
        }
      }
    },
    {
      dataField: 'is_cellular_violation',
      text: 'Cellular',
      align: 'center',
      headerStyle: {textAlign: 'center'},
      formatter: (cell, row) => {
        if(cell) {
          return (
            <Tooltip id={`as-${row.id}`} content="Cellular radio on">
              <div className="fa-lg me-4">
                <span className="fa-layers fa-fw">
                  <FontAwesomeIcon icon={faCircle} size="2x" color={cell ? variables.privoroGold : variables.privoroBlue} />
                  <FontAwesomeIcon data-testid="cell-violation-icon" transform="shrink-1 right-6" icon={cell ? faTriangleExclamation : faCheck } color={cell ? variables.black : variables.white}/>
                </span>
              </div>
            </Tooltip>
          );
        }
      }
    },
    {
      dataField: 'state_update_time',
      text: 'LAST UPDATED (UTC)',
      sort: true,
      formatter: (cell, row) => {
        const content = row?.is_offline ? 'User offline' : 'User online';
        return (
          <div className="fa-lg">
            <Tooltip id={`as-${row.id}`} content={content}>
              <span className="fa-layers fa-fw me-4">
                <FontAwesomeIcon icon={faCircle} size="2x" color={row.is_offline ? variables.privoroOffGray : variables.privoroBlue} />
                <FontAwesomeIcon data-testid="online-icon" transform="shrink-1 right-6" icon={row.is_offline ? faWifiSlash : faWifi } color={row.is_offline ? variables.black : variables.white}/>
              </span>
            </Tooltip>
            <span style={{fontSize: '0.75rem'}}>{dateFormat(cell)}</span>
          </div>            
        );
      }
    }
  ];
  return (
    <>
      <ActionBar
        showHidden={false}
        showSearchBar={true}
        searchChange={setSearch}
        title="Monitoring Dashboard"
      />
      <FilterBar onChange={setFilter} />
      <div className="dashboard-table">
        <Table
          testId="dashboard-table"
          data={deviceStates}
          columns={columns}
          loading={loading}
          pageSize={pageSizeRecoil}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalRecords={totalRecordsState}
          setSort={setSort}
          disableRowClick={true}
        />
      </div>
    </>
  );
};

const DEVICE_STATES = gql`  
query getDeviceStates ($filters: GenericFilters) {
  DeviceStates (filters: $filters) {
    result {
      device {
        serial_number
        id
        user {
          id
          username
          firstname
          lastname
        }
        device_configuration {
          is_hdm_enabled
          is_hdm_enabled_expiry
          is_hdm_enabled_end_date
          is_anti_surveillance_enabled
          is_anti_surveillance_enabled_expiry
          is_anti_surveillance_enabled_end_date
        }
        product_type {
          id
          description
          greyhound_family
          samsung_family
        }
      }
      policy_alert {
        id
        reason
        modified_at
      }
      is_low_batt_violation
      is_antisurveillance_violation
      is_cellular_violation
      is_check_in
      latest_checked_in_time
      battery_life
      is_offline
      hdm_preset_option
      state_update_time      
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    }
  }
}
`;

export default Dashboard;
